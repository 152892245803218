import React       from 'react';
import { graphql } from 'gatsby';
import {
  AiFillQuestionCircle
}                  from 'react-icons/ai';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  useTheme
}                  from '@chakra-ui/react';

import Wrapper      from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Spacer       from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Heading      from '@interness/web-core/src/components/text/Heading/Heading';
import CallToAction from '@interness/theme-sonora/src/components/CallToAction/CallToAction';

const FAQPage = (props) => {
  const faq = props.data.faq.nodes;
  const theme = useTheme();
  const color = theme.colorMode === 'light' ? 'rgba(0,0,0,1)' : 'rgba(255,255,255,1)';
  return (
    <Wrapper>
      <Spacer/>
      <Heading icon={<AiFillQuestionCircle/>} subtitle="Ihre Fragen von uns beantwortet">FAQ</Heading>
      <Accordion allowToggle>
        {faq.map(item => {
          return (
            <AccordionItem sx={{
              h2: {
                margin: '10px 0'
              },
              svg: {
                color: color
              }
            }}>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    <b>{item.title}</b>
                  </Box>
                  <AccordionIcon/>
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Box sx={{
                  ol: {
                    paddingLeft: '1.5rem',
                  }
                }}>
                  <div dangerouslySetInnerHTML={{ __html: item.content }}/>
                </Box>
              </AccordionPanel>
            </AccordionItem>
          )
        })}
      </Accordion>
      <Spacer/>
      <CallToAction imageFileTitle="theme-sonora-call-to-action-background-trauringe"/>
      <Spacer/>
    </Wrapper>
  )
};

export default FAQPage;

export const query = graphql`
    query {
        faq: allDirectusWeddingRingFaq(filter: {id: {ne: "dummy"}}) {
            nodes {
                title
                status
                content
            }
        }
    }
`;
